import API from 'Services/API';

const Service = new API('/api/vuestore');

const useCrossSelling = () => {  
  const getRichRelevanceProducts = async (richRelevanceData) => {
    let placements = [];
    await Service.getRichRelevanceProducts(richRelevanceData)    
      .then(
        (result) => {
          placements = result;
        },
        (error) => {
          console.error(error);
        }
      )
      .catch((error) => {        
        console.error(error);
      });
    return placements;
  };

  return {
    getRichRelevanceProducts,
  };
};

export default useCrossSelling;
