<template>
  <div
    class="cross-selling__container"
    :class="specificClass">
    <h2
      v-if="title"
      id="cross-selling-title"
      class="product_detail-title"
      data-custom-brand
      data-testid="crossSellingTitle">
      {{ title }}
    </h2>
    <div class="cross-selling__scroll-container">
      <AsyncStock
        v-slot="{ stockProducts }"
        :products="products">
        <MultiCarousel
          :items="stockProducts"
          :visible-items="visibleItems"
          :selected-item-index="selectedItemIndex"
          :wheel-drag="true"
          :disable-scroll="isModalSizesOpen"
          @selected-index="handleSelectedIndex">
          <template #default="product">
            <div class="cross-selling__item">
              <ProductPreview
                :product="product.item"
                :active="false"
                :cross-selling-type="type"
                :add-to-cart-index-pushed="addToCartIndexPushed"
                :data-layer="dataLayer[product.index]"
                :force-show-brand="true"
                :position="product.index + 1"
                @index-pushed-update="updateAddToCartIndexPushed" />
            </div>
          </template>
        </MultiCarousel>
      </AsyncStock>
      <div
        v-if="showForwardControl"
        class="scroll-control scroll-control__forward"
        @click="scrollForward"></div>
      <div
        v-if="showBackwardControl"
        class="scroll-control scroll-control__backward"
        @click="scrollBackward"></div>
    </div>
  </div>
</template>

<script>
import AsyncStock from 'Components/Stock/AsyncStock.vue';
import MultiCarousel from 'CommonComponents/Carousel/MultiCarousel.vue';
import ProductPreview from 'Components/ProductPreview/ProductPreview.vue';
import { mapGetters } from 'vuex';


export default {
  name: 'CrossSelling',
  components: {
    AsyncStock,
    MultiCarousel,
    ProductPreview
  },
  provide: function() {
    return {
      crossSellingType: this.type,
      hidePromotions: !this.mustShowCrossSellingPromotions,
      getDataLayer: () => { return this.dataLayer }
    };
  },
  props: {
    dataLayer: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    products: {
      type: Array,
      default: () => []
    },
    specificClass: {
      type: String,
      default: '',
    },
    visibleItems: {
      type: Number,
      default: 4
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedItemIndex: 0,
      showForwardControl: false,
      showBackwardControl: false,
      addToCartIndexPushed: 1
    };
  },
  computed: {
    ...mapGetters('selectorState', {
      isModalSizesOpen: 'getModalStatus'
    }),
    ...mapGetters('pdp', {
      mustShowCrossSellingPromotions: 'mustShowCrossSellingPromotions'
    })
  },
  methods: {
    handleSelectedIndex(index) {
      this.selectedItemIndex = index;
      this.showForwardControl = this.canScrollToEnd();
      this.showBackwardControl = this.canScrollToStart();
    },
    canScrollToEnd() {
      return this.selectedItemIndex + this.visibleItems < this.products.length;
    },
    canScrollToStart() {
      return this.selectedItemIndex > 0;
    },
    scrollForward() {
      if (this.canScrollToEnd()) {
        this.selectedItemIndex += 1;
      }
    },
    scrollBackward() {
      if (this.canScrollToStart()) {
        this.selectedItemIndex -= 1;
      }
    },
    updateAddToCartIndexPushed() {
      this.addToCartIndexPushed += 1;
    }
  },
};
</script>
