/**
 * Enum for page types
 * @readonly
 * @enum type of page
 */
const PAGE_TYPES = Object.freeze({
  ADD_TO_CART_PAGE: 'add_to_cart_page',
  CART_PAGE: 'cart_page',
  HOME_PAGE: 'home_page',
  STATIC_PAGE: 'static_page',
  SEARCH_PAGE: 'search_page',
  CATEGORY_PAGE: 'category_page',
  ITEM_PAGE: 'item_page',
  BRAND_PAGE: 'brand_page',
  ERROR_PAGE: 'error_page',
});

export default PAGE_TYPES;
