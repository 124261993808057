<template v-if="showContent">
  <div
    v-for="(placement, index) in richRelevanceData?.placements"
    :key="index">
    <CrossSelling
      data-testid="cross-selling__container__plp_rr"
      :dataLayer="placement.datalayer"
      :products="placement.products"
      :specific-class="'cross-selling__container__plp_rr'"
      :title="placement.placementTitle"
      :type="CROSS_SELLING_TYPES.RR"
      :visible-items="isMobileDevice ? 1.25 : undefined" />
  </div>
</template>

<script>
import { onMounted, ref, toRefs, watch } from "vue";
import useCrossSelling from "Composables/useCrossSelling";
import CrossSelling from "Components/ProductDetail/CrossSelling/CrossSelling.vue";
import CROSS_SELLING_TYPES from 'CommonUtils/crossSellingTypes';
import UtilsAnalytics from "CommonUtils/analytics";
import UtilsCookies from "CommonUtils/cookies.js";
import useNavigatorDetect from "CommonComposables/navigatorDetect.js";
import { mapGetters } from "CommonUtils/store/state.js";

export default {
  components: {
    CrossSelling,
  },
  props: {
    brandName: { type: String, required: false, default: undefined },
    categoryId: { type: String, required: false, default: undefined },
    forceRecall: { type: Boolean, default: false },
    pageType: { type: String, required: true },
    productId: { type: String, default: undefined },
    searchTerm: { type: String, default: undefined },
    showContent: { type: Boolean, default: true },
  },
  setup(props) {
    const { getRichRelevanceProducts } = useCrossSelling();
    const { forceRecall } = toRefs(props);
    const richRelevanceData = ref({});
    const { isMobileDevice } = useNavigatorDetect();

    const { isLogged, getUser } = mapGetters("cart", {
      isLogged: "isLogged",
      getUser: "getUser",
    });

    watch(forceRecall, (newValue) => {
      if (newValue) {
        obtainRichRelevanceProducts();
      }
    });

    onMounted(() => {
      obtainRichRelevanceProducts();
    });

    async function obtainRichRelevanceProducts() {
      const userId = isLogged?.value && getUser?.value?.id;
      const sessionId = UtilsCookies.getCookieValue("session_id");
      const richRelevanceParams = {
        brandName: props.brandName,
        categoryId: props.categoryId,
        pageType: props.pageType,
        productId: props.productId,
        searchTerm: props.searchTerm,
        rrCookie: getRRCookie(),
        session_id: sessionId,
      };

      if (userId) {
        richRelevanceParams.pw = userId;
      }
      richRelevanceData.value = await getRichRelevanceProducts(richRelevanceParams);
      setRRCookie(richRelevanceData.value.rrCookie);
      sendModulePush(richRelevanceData.value);
    }

    function getRRCookie() {
      if (typeof window === "undefined") return;
      return window.sessionStorage.getItem("rrCookie");
    }

    function setRRCookie(cookie) {
      if (cookie) {
        if (typeof window === "undefined") return;
        window.sessionStorage.setItem("rrCookie", cookie);
      }
    }

    function sendModulePush() {
      if (!richRelevanceData.value?.placements) {
        return;
      }

      richRelevanceData.value?.placements.forEach((placement, index) => {
        const crossSellingType = "rr";
        UtilsAnalytics.sendProductModulesPrint(
          placement.datalayer,
          crossSellingType,
          index,
          placement.placementTitle
        );
      });
    }

    return { isMobileDevice, richRelevanceData, CROSS_SELLING_TYPES };
  },
};
</script>
