<template>
  <slot :stockProducts="stockProducts"></slot>
</template>

<script>
import useAsyncStock from 'Composables/useAsyncStock.js';

export default {
  name: 'AsyncStock',
  props: {
    products: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  setup(props) {
    const { products } = toRefs(props);
    const { stockProducts } = useAsyncStock(products);

    return { stockProducts };
  },
};
</script>